import React, { useEffect, useRef } from 'react'
import { useState } from 'react'
import { ReactReader, ReactReaderStyle } from 'react-reader';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as storyActions from "store/story/actions";
import _debounce from 'lodash/debounce';
import { Modal } from 'antd'

import './EpubReader.scss'
import useSyllables from '../../hooks/useSyllables';
import MeaningModal from '../meaningModal/MeaningModal';
import SyllablesModal from '../syllablesModal/SyllablesModal';
import useTextToSpeech from '../../hooks/useTexttoSpeech';

const EpubReader = ({ url, handlePrevFunc, handleNextFunc, getMeaningsfromDict, bookLoadedFunc, renditionRef, fontSize, setIsLoaded, isLoaded, currentPageIndex, lastPage, viewerRef, tocLength, setLocationChanged, selections, setSelections }) => {

    const [location, setLocation] = useState(null);
    const [openShowMeaningModal, setOpenShowMeaningModal] = useState(false)
    const [openShowSyllablesModal, setOpenShowSyllablesModal] = useState(false)
    const [meaningData, setMeaningData] = useState(null)
    const [syllables, setSyllables] = useState([])
    const [tooltipPosition, setTooltipPosition] = useState({ top: 0, left: 0 });
    const [showTooltip, setShowTooltip] = useState(false);
    const [loadingMeanings, setLoadingMeanings] = useState(false);

    const tooltipRef = useRef(null)
    const { startReading } = useTextToSpeech()

    const ownStyles = {
        ...ReactReaderStyle,
        arrow: {
            ...ReactReaderStyle.arrow,
            display: 'none'
        },
        typography: {
            ...ReactReaderStyle.typography,
            fontSize: '500%'
        }
    }

    const getMeaning = async (selections) => {
        if (selections?.text?.length > 0) {
            setLoadingMeanings(true)
            const res = await getMeaningsfromDict(selections?.text)
            if (res) {
                setMeaningData(res?.data)
            }
            setLoadingMeanings(false)
        }
    }

    const cleanText = (text) => {
        return text.replace(/[^a-zA-Z0-9]/g, ''); // Removing all non-alphanumeric characters
    };

    const closeMeaningModal = () => {
        setOpenShowMeaningModal(false)
    }

    const closeSyllablesModal = () => {
        setOpenShowSyllablesModal(false)
    }

    const onLocationChanged = (epubcfi) => {
        setLocation(epubcfi);
        setLocationChanged(true)
    };

    useEffect(() => {
        if (renditionRef.current) {
            const debouncedSetRenderSelection = _debounce(async (cfiRange, contents) => {
                if (renditionRef.current) {
                    const selection = contents.window.getSelection();
                    if (!selection.rangeCount) return;

                    const range = selection.getRangeAt(0);
                    const rect = range.getBoundingClientRect();

                    if (!rect.width || !rect.height) return;
                    // console.log(renditionRef.current.getRange(cfiRange), rect, contents.window.scrollY, contents.window.scrollX, window.screenX, window.screenY)
                    const cleanedText = cleanText(renditionRef.current.getRange(cfiRange).toString())
                    // const range = renditionRef.current.getRange(cfiRange);
                    // const rect = range.getBoundingClientRect();
                    const iframe = viewerRef.current.querySelector('iframe');
                    const iframeRect = iframe ? iframe.getBoundingClientRect() : { top: 0, left: 0 };
                    const epubScrollY = contents.document.documentElement.scrollTop;
                    // console.log(iframeRect, iframe, epubScrollY)
                    setSelections(
                        {
                            text: cleanedText,
                            cfiRange,
                            rect
                        }
                    );
                    setTooltipPosition({
                        // top: rect.y + contents.window.scrollY + iframeRect.top -30, // Adjusted to show above selection
                        // left: rect.x + rect.width + 40 + contents.window.scrollX,
                        top: rect.top + iframeRect.top + contents.window.scrollY - 40, // Adjusted to show above selection
                        left: rect.left + iframeRect.left + contents.window.scrollX,
                    });
                    setShowTooltip(true);
                    renditionRef.current.annotations.add(
                        'highlight',
                        cfiRange,
                        {},
                        (e) => console.log('click on selection', cfiRange, e),
                        'hl',
                        { fill: '#c899eb', 'fill-opacity': '0.5', 'mix-blend-mode': 'multiply' }
                    )
                    // const selection = contents.window.getSelection();
                    //         const range = selection.getRangeAt(0);
                    //         const rect = range.getBoundingClientRect();
                    // console.log(selection);
                    selection.removeAllRanges();

                }
            }, 500);
            renditionRef.current.on('selected', debouncedSetRenderSelection)
            return () => {
                renditionRef.current.off('selected', debouncedSetRenderSelection)
            }
        }
    }, [setSelections, selections])

    useEffect(() => {
        setMeaningData(null)
        getMeaning(selections)
        let syllables = useSyllables(selections?.text)
        setSyllables(syllables)
    }, [selections])


    const handleBookLoaded = (book) => {
        if (bookLoadedFunc) bookLoadedFunc()
    };

    const openSyllablesModal = () => {
        if (syllables?.length > 0) {
            setShowTooltip(false)
            setOpenShowSyllablesModal(true)
            renditionRef.current.annotations.remove(
                selections?.cfiRange,
                'highlight',
            )
        }
    }

    const openMeaningsModal = () => {
        // if (loadingMeanings) {
        setShowTooltip(false)
        setOpenShowMeaningModal(true)
        renditionRef.current.annotations.remove(
            selections?.cfiRange,
            'highlight',
        )
        // }
    }

    const readAloud = (text) => {
        setShowTooltip(false)
        startReading(text)
        renditionRef.current.annotations.remove(
            selections?.cfiRange,
            'highlight',
        )
    }

    return (
        <>
            <div className='epub_container'>
                {
                    window.innerWidth > 650 &&
                    <button className='pdf_control_btn' onClick={handlePrevFunc} disabled={currentPageIndex === 0}>{'<'}</button>
                }
                <div style={{ height: '100%', display: 'flex', flexDirection: 'column', width: '90%' }} id="epub-render" onBlur={() => {
                    renditionRef.current.annotations.remove(
                        selections?.cfiRange,
                        'highlight',
                    ); setShowTooltip(false)
                }}>
                    <div style={{ flex: 1 }} ref={viewerRef}>
                        <ReactReader
                            url={url}
                            location={location}
                            locationChanged={(rendition) => onLocationChanged(rendition)}
                            showToc={false}
                            onReady={(book) => {
                                // console.log("logged")
                                handleBookLoaded(book); setIsLoaded(true)
                            }}
                            getRendition={(rendition) => {
                                renditionRef.current = rendition;
                                // renditionRef.current.display(0)
                                renditionRef.current.themes.default({
                                    body: { 'overflow-y': 'scroll', 'height': '100%' },
                                    '::selection': {
                                        background: '#c899eb'
                                    }
                                });
                                renditionRef.current.flow('scrolled-doc');
                                renditionRef.current.themes.fontSize(`${fontSize}%`);
                                setSelections([])
                                rendition.on("rendered", (book) => {
                                    handleBookLoaded(book); setIsLoaded(true)
                                });
                                // setIsLoaded(true)

                            }}
                            styles={ownStyles}
                        />

                        {showTooltip && (
                            <div
                                className='epub_tooltip_container'
                                ref={tooltipRef}
                                style={{

                                    top: tooltipPosition.top,
                                    left: tooltipPosition.left,

                                }}
                            >
                                <div className='epub_tooltip_button' onClick={() => readAloud(selections?.text)}>🔊 Listen</div>
                                <div className='epub_tooltip_button' onClick={() => openMeaningsModal()}>📖 Meaning</div>
                                <div className='epub_tooltip_button'
                                    onClick={() => openSyllablesModal()}
                                >
                                    ✂ Syllables
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                {
                    window.innerWidth > 650 &&
                    <button className='pdf_control_btn' onClick={handleNextFunc} disabled={lastPage || currentPageIndex === tocLength - 1}>{'>'}</button>
                }
            </div>



            {
                <Modal
                    centered
                    width="70%"
                    height="70%"
                    className=""
                    visible={openShowMeaningModal}
                    onCancel={() => setOpenShowMeaningModal(false)}
                    footer={null}
                    key={1}
                    destroyOnClose
                >
                    <MeaningModal data={meaningData && meaningData} loading={loadingMeanings} word={selections?.text} closeModal={closeMeaningModal} />

                </Modal>
            }
            {
                <Modal
                    centered
                    width="70%"
                    height="70%"
                    className=""
                    visible={openShowSyllablesModal}
                    onCancel={() => setOpenShowSyllablesModal(false)}
                    footer={null}
                    key={2}
                    destroyOnClose
                >
                    <SyllablesModal word={selections?.text} data={syllables && syllables} closeModal={closeSyllablesModal} />

                </Modal>
            }

        </>
    )
}

export default connect(
    ({ detailedStory }) => ({ ...detailedStory }),
    (dispatch) => bindActionCreators({ ...storyActions }, dispatch)
)(EpubReader);