import React, { useEffect, useState, useRef } from "react";
// import "./style.scss";
import {FaSlidersH, FaStop } from "react-icons/fa";
import Loader from "assets/image/loader.gif";
import micListeningGif from "assets/image/mic_listening.gif";
import { storage as LocalStorage } from "services/config/storage";
import Navbar from "../../components/Navbar";
import _debounce from "lodash/debounce";
import { GiSpeaker, GiSpeakerOff } from "react-icons/gi";
import { IoIosPause, IoIosPlay } from "react-icons/io";
import { FaMicrophone } from "react-icons/fa";
import { ImExit } from "react-icons/im";
import { API_URL } from "../../services/config/apiUrl";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as storyActions from "store/story/actions";

import '../../components/MediaViewer/MediaViewer.scss'
import EpubReader from "../../components/EpubReader/EpubReader";
import { Button } from "antd";

const StoryReadAloud = ({ data }) => {
    const [showStartButton, setShowStartButton] = useState(false);
    const [recorder, setRecorder] = useState(null);
    const [session_id, setSessionId] = useState("");
    const [process_id, setProcessId] = useState("");
    const [error, setError] = useState(null);
    const [stopRecButton, setStopRecButton] = useState(false);
    const [showLoading, setShowLoading] = useState(false);
    const [isReadAloud, setIsReadAloud] = useState(false);
    const [isPaused, setIsPaused] = useState(false);
    const [playbackRate, setPlayBackRate] = useState(1);
    const [selections, setSelections] = useState([]);
    const [currentWordIndex, setCurrentWordIndex] = useState(1);
    const [currentParaIndex, setCurrentParaIndex] = useState(1);
    const [voices, setVoices] = useState([]);
    const [selectedVoice, setSelectedVoice] = useState('');
    const [audioFile, setAudioFile] = useState(null)
    const [currentPageIndex, setCurrentPageIndex] = useState(0);
    const [locationChanged, setLocationChanged] = useState(false)
    const [lastPage, setLastPage] = useState(false)
    const [isLoaded, setIsLoaded] = useState(false)
    const [tocLength, setTocLength] = useState(0)
    const [micLoader, showMicLoader] = useState(false)
    const [isSliderOpen, setOpenSlider] = useState(false)

    const viewerRef = useRef(null);
    const renditionRef = useRef(null);
    const utteranceRef = useRef(null);
    const textNodesRef = useRef([]);
    const speechSynthesisRef = window.speechSynthesis

    const updatePlaybackRate = (e) => {
        const newPlaybackRate = parseFloat(e.target.value);
        setPlayBackRate(newPlaybackRate);
        stopReadAloud()
    };

    const recordAudio = () =>
        new Promise(async (resolve) => {
            // console.log("inside rec promise",navigator.mediaDevices);
            const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
            // console.log("stream", stream);
            //   setStream(stream);
            const mediaRecorder = new MediaRecorder(stream);
            const audioChunks = [];
            // console.log(stream);
            // console.log(mediaRecorder);

            mediaRecorder.addEventListener("dataavailable", (event) => {
                audioChunks.push(event.data);
            });

            const start = () => {
                mediaRecorder.start();
                // setRecording(true);
            };

            const stop = () =>
                new Promise((resolve) => {
                    // console.log("called stop method");
                    mediaRecorder.addEventListener("stop", () => {
                        const audioBlob = new Blob(audioChunks, { type: "audio/wav" });
                        const audioUrl = URL.createObjectURL(audioBlob);
                        const audio = new Audio(audioUrl);
                        const play = () => audio.play();
                        // setRecording(false);
                        // setAudioPlayer({ audioBlob, audioUrl, play });
                        resolve({ audioBlob, audioUrl, play });
                    });
                    // console.log("media recorder state", mediaRecorder.state);
                    if (mediaRecorder.state !== "inactive") {
                        mediaRecorder.stop();
                    }
                });

            resolve({ start, stop });
        });

    function getRandomID() {
        return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
            /[xy]/g,
            function (c) {
                var r = (Math.random() * 16) | 0,
                    v = c == "x" ? r : (r & 0x3) | 0x8;
                return v.toString(16);
            }
        );
    }

    const getShownText = () => {
        // console.log("get shown func");
        var range = renditionRef?.current.getRange(
            renditionRef?.current.currentLocation().start.cfi
        );
        var endRange = renditionRef?.current.getRange(
            renditionRef?.current.currentLocation().end.cfi
        );
        // console.log(range);
        // console.log(endRange);
        range.setEnd(endRange.startContainer, endRange.startOffset);
        let targetPhrase = range.toString();
        // setTargetPhrase(targetPhrase);
        // console.log(targetPhrase);
    };

    const stopRecording = async () => {
        showMicLoader(false)
        setShowStartButton(true);
        setStopRecButton(false);
        setShowLoading(true);
        setShowStartButton(false);
        try {
            // console.log("try");
            const audio = await recorder.stop();
            // console.log("audio:", audio);

            var wavfromblob = new File([audio.audioBlob], "incomingaudioclip.wav");
            var audioFormdata = new FormData();
            audioFormdata.append("file", wavfromblob, "New Recording.wav");

            const response = await fetch(
                `${API_URL}misc/v1/media-v2/`,
                {
                    method: "post",
                    body: audioFormdata,
                }
            );

            if (!response.ok) {
                // Handle HTTP errors (e.g., 4xx, 5xx)
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            const text = await response.json();
            // var token = LocalStorage.fetch.authToken();
            // console.log("Token", token);

            const fluencyResponse = await fetch(
                `${API_URL}misc/v1/reading-fluency-iitb/`,
                {
                    method: "post",
                    headers: {
                        "Content-Type": "application/json",
                        // authorization: `JWT ${token}`,
                    },
                    body: JSON.stringify({
                        audio_path: text?.result?.file,
                        book_title: data?.name,
                        process_id: process_id,
                        session_id: session_id,
                        grade: LocalStorage.fetch.defaultChild()?.grade ? LocalStorage.fetch.defaultChild()?.grade?.name : 'Test POC',
                        page: renditionRef?.current.location.start.index - 3,
                    }),
                }
            );

            if (!fluencyResponse.ok) {
                // Handle API errors
                const fluencyError = await fluencyResponse.json();
                const errorMessage =
                    fluencyError?.ExceptionMessage ||
                    "Internal Server Error. Contact Administrator.";

                // Update loader and button states
                setShowLoading(false);
                setStopRecButton(false);
                setShowStartButton(true);
                setError(errorMessage);
            } else {
                setAudioFile(text?.result?.file)
                setError(null);
                let resp = await fluencyResponse.json();
                const allPTags = document.querySelectorAll("p");

                var iframe = document.querySelector("iframe");
                var targetHtml = " ";
                var ePubTextList = iframe.contentWindow.document.querySelectorAll("p");
                // console.log("epublist", ePubTextList);
                var imagecontainer =
                    iframe.contentWindow.document.querySelectorAll("img");
                // console.log("imagecontainer", imagecontainer);
                if (imagecontainer.length > 0) {
                    imagecontainer[0].style.width = "200px";
                    imagecontainer[0].style.height = "200px";
                }
                ePubTextList.forEach(function (p) {
                    targetHtml += p.outerHTML;
                    p.remove();
                });
                var ifrDoc = iframe.contentDocument;

                var elem = ifrDoc.createElement("div");

                elem.innerHTML = resp?.result?.marked_text;
                ifrDoc.body.appendChild(elem);

                setShowLoading(false);
                setShowStartButton(true);
            }
        } catch (error) {
            console.error("Error in stopRecording:", error);

            // Update loader and button states
            setShowLoading(false);
            setStopRecButton(false);
            setShowStartButton(true);
            setError(error);
        }
    };

    const startRecording = async () => {
        // console.log("start func");
        showMicLoader(true)
        renditionRef.current.annotations.remove(selections.cfiRange, "highlight");
        speechSynthesisRef.cancel()
        setIsReadAloud(false)
        setAudioFile(null)

        if (error) setError(false);

        var session_id = getRandomID();
        setSessionId(session_id);
        // console.log(session_id);
        var process_id = getRandomID();
        setProcessId(process_id);

        setStopRecButton(true);
        getShownText();

        var recorder = await recordAudio();
        setRecorder(recorder);
        // console.log("Recorder", recorder);
        recorder.start();

    };

    const FinishStory = async () => {
        setAudioFile(null)
        window.location = "/read-along";
    };

    const pauseReading = () => {
        speechSynthesisRef.pause()
        setIsPaused(true)
    }

    const resumeReading = () => {
        speechSynthesisRef.resume()
        setIsPaused(false)
    }

    useEffect(() => {
        if (error !== null) {
            var iframe = document.querySelector("iframe");
            var targetHtml = " ";
            var ePubTextList = iframe.contentWindow.document.querySelectorAll("p");
            if (error) {
                ePubTextList.forEach(function (p) {
                    targetHtml += `<p style="color:red;">${p.innerText}</p>`;
                    // console.log(targetHtml);
                    p.remove();
                });
                var ifrDoc = iframe.contentDocument;
                var elem = ifrDoc.createElement("div");
                elem.innerHTML = targetHtml;
                ifrDoc.body.appendChild(elem);
            } else {
                ePubTextList.forEach(function (p) {
                    targetHtml += `<p>${p.innerText}</p>`;
                    // console.log(targetHtml);
                    p.remove();
                });
                var ifrDoc = iframe.contentDocument;
                var elem = ifrDoc.createElement("div");
                elem.innerHTML = targetHtml;
                ifrDoc.body.appendChild(elem);
            }
        }
    }, [error]);

    useEffect(() => {

        if (viewerRef.current) {
            // const doc = viewerRef.current.querySelector('iframe')?.contentWindow.document
            const doc_body = viewerRef.current.querySelector('iframe')?.contentWindow?.document?.body
            const words = doc_body?.getElementsByTagName('span');
            let currentWord = ''
            // console.log(words)
            for (let i = 0; i < words?.length; i++) {
                // console.log(words[i])
                words[i].classList.remove('highlight');
                words[i].style.background = "none"
            }
            if (currentWordIndex === 0 || currentParaIndex === 0) currentWord = doc_body?.querySelector(`#word-${currentParaIndex}${currentWordIndex + 1}`)
            else currentWord = doc_body?.querySelector(`#word-${currentParaIndex}${currentWordIndex + 1}`)
            // console.log(currentWord?.innerText, `#word-${currentParaIndex}${currentWordIndex}`)
            if (currentWord) {
                currentWord.classList.add('highlight');
                currentWord.style.background = "yellow"
            }
        }
    }, [currentWordIndex]);

    useEffect(() => {
        if (!speechSynthesisRef.speaking) {
            setIsReadAloud(false)
        }
    }, [speechSynthesisRef.speaking])

    const setTextNodes = () => {
        // console.log(viewerRef.current)
        if (viewerRef.current) {
            const doc_body = viewerRef.current.querySelector('iframe')?.contentWindow?.document

            let textNodes = getTextNodes(doc_body);
            textNodesRef.current = textNodes

        }
    }

    const getTextNodes = (doc) => {
        const extractedContent = [];
        let Alltext = ''

        // Helper function to process each element and its children recursively
        const processElement = (element, index) => {

            // Process paragraph <p> elements
            if (element.tagName.toLowerCase() === 'p') {
                const text = element.textContent.trim();
                if (text) {
                    extractedContent.push({
                        type: 'text',
                        content: text,
                        index: `text-${index}`,
                    });

                    Alltext = Alltext.concat(text)
                    Alltext = Alltext.concat(' ')
                }
            }
            // Process image <img> elements directly
            else if (element.tagName.toLowerCase() === 'img') {
                extractedContent.push({
                    type: 'image',
                    src: element.getAttribute('src'),
                    index: `image-${index}`,
                });
            }
            else if (element.tagName.toLowerCase() === 'div') {
                // Recursively process all child nodes of the current div
                Array.from(element.children).forEach((child, childIndex) => {
                    processElement(child, `${index}-${childIndex}`);
                });
            }
        };

        // Start processing the children of the body element
        Array.from(doc.body.children).forEach((child, index) => {
            processElement(child, index);
        });

        // setAllText(Alltext)
        return extractedContent;
    };

    const startReadAloud = async () => {
        setIsReadAloud(true)
        renditionRef.current.annotations.remove(selections.cfiRange, "highlight");

        const doc_body = viewerRef.current.querySelector('iframe').contentWindow.document.body

        let textHtml = `<div>`
        if (textNodesRef.current.length > 0) {
            textNodesRef.current.forEach((node, ind) => {
                // console.log(node)
                const content = node.type === 'text' ? node.content : null
                const src = node.type === 'image' ? node.src : null
                if (node.type === 'text') {
                    if (content) {
                        textHtml = textHtml.concat('<p>')
                        const words = content.split(/\s+/);
                        let wrappedText = words.map((word, index) => `<span id="word-${ind + 1}${index + 1}">${word} </span>`).join('');

                        textHtml = textHtml.concat(wrappedText)
                        textHtml = textHtml.concat('</p>')

                        utteranceRef.current = new SpeechSynthesisUtterance(content);
                        const voice = voices.find(voice => voice.name === selectedVoice);
                        // console.log(voice)
                        if (voice) {
                            utteranceRef.current.voice = voice;
                        }
                        utteranceRef.current.lang = 'en-IN';
                        utteranceRef.current.rate = playbackRate;
                        utteranceRef.current.pitch = 1;
                        utteranceRef.current.onboundary = (event) => {

                            if (event.name === 'word') {
                                const charIndex = content.slice(0, event.charIndex).split(/\s+/).length - 1;
                                // console.log(charIndex,currentParaIndex,ind)
                                setCurrentWordIndex(charIndex);
                            }
                            setCurrentParaIndex(ind + 1)
                        };
                        utteranceRef.current.onend = () => {
                            setCurrentParaIndex((prev) => prev + 1)
                            setCurrentWordIndex(0);
                        };
                        speechSynthesisRef.speak(utteranceRef.current);
                        setIsReadAloud(true);
                    }
                }
                else {
                    textHtml = textHtml.concat(`<img style="width:300px;height:300px" src="${src}"/>`)
                }
            })
            textHtml = textHtml.concat('</div>')
            // console.log(textHtml)
            doc_body.innerHTML = textHtml;
        }

        doc_body.style.display = "flex"
        doc_body.style.gap = '8px'

    }

    const stopReadAloud = () => {
        if (speechSynthesisRef.speaking) {
            speechSynthesisRef.cancel();
            setIsReadAloud(false)
            setIsPaused(false)
        }
    }

    const loadVoices = () => {
        const voices = speechSynthesisRef.getVoices();
        let indianVoices = voices.filter(voice => voice.lang === 'en-IN');
        let defaultVoices = voices.filter((voice) => voice.localService === true)
        setVoices(defaultVoices);
        if (voices.length > 0) {
            if (indianVoices.length > 0) {
                let indianVoice = indianVoices?.find((voice) => voice?.name?.includes("Heera"))
                    ? indianVoices?.find((voice) => voice?.name?.includes("Heera"))
                    : indianVoices[0];
                setSelectedVoice(indianVoice?.name);
            } else {
                let defaultVoice = voices[0];
                setSelectedVoice(defaultVoice?.name)
            }
        }
    };

    const handleNext = async () => {
        if (currentPageIndex < tocLength) {
            setAudioFile(null)
            showMicLoader(false)
            if (renditionRef.current.location.start.index < 4) {
                setShowStartButton(true);
                await renditionRef.current.display(4);
            } else {
                setShowStartButton(true);
                await renditionRef.current.next()
            }

            if (locationChanged) {
                setCurrentPageIndex(currentPageIndex + 1)
                setLocationChanged(false)
                if (renditionRef.current.currentLocation()?.atEnd) {
                    setLastPage(true)
                }
            }
            speechSynthesisRef.cancel();
            setIsReadAloud(false)
            setIsPaused(false)
            setTextNodes()
        } else {
            setLastPage(true)
        }
    };

    const handlePrev = async () => {
        setAudioFile(null)
        showMicLoader(false)
        setTextNodes()
        setLastPage(false)
        setCurrentPageIndex(currentPageIndex - 1)
        setIsPaused(false)

        speechSynthesisRef.cancel()
        setIsReadAloud(false)

        if (renditionRef?.current.location.start.index == 4) {
            setShowStartButton(false);
            await renditionRef?.current.display(0);
        } else {
            await renditionRef.current.prev()
        }
    };


    const handleBookLoaded = (book) => {
        const toc = renditionRef?.current?.book?.spine?.items; // Access the TOC from the book
        setTocLength(toc?.length);

        const screenWidth = window.innerWidth;
        var iframe = document.querySelector("iframe");
        var iframeDocument =
            iframe.contentDocument || iframe.contentWindow.document;

        // Find the image element within the iframe content
        let body = iframeDocument.querySelector("body");
        let para = iframeDocument.querySelectorAll("p");
        var image = iframeDocument.querySelector(".image img");
        var image_container = iframeDocument.querySelector(".image");

        // Check if the screen width is greater than a certain threshold (e.g., 768px)
        if (screenWidth > 768) {
            // Apply styles to the image
            if (image) {
                image.style.width = "300px";
                image.style.height = "300px";
            }
            if (body) {
                body.style.display = "flex";
                body.style.flexDirection = "column";
                body.style.justifyContent = "center";
                // body.style.gap = "8px";
                body.style.alignItems = "center";
            }
            if (para) {
                para.forEach((par) => {
                    par.style.margin = "0";
                })
            }
        } else {
            if (image_container) {
                image_container.style.width = "200px";
                image_container.style.height = "200px";
            }
            if (image) {
                image.style.width = "100%";
                // image_container.style.height = "300px";
            }

            if (body) {
                body.style.width = "100%";
                body.style.display = "flex";
                body.style.flexDirection = "column";
                body.style.justifyContent = "center";
                body.style.alignItems = "center";

            }
            if (para) {
                para.forEach((par) => {
                    par.style.width = "100%";
                })
            }
        }
        // console.log('Table of Contents:', toc,book,book.loaded,renditionRef.current?.book); // Optional: Inspect the TOC
    };

    useEffect(() => {
        if (renditionRef?.current) {
            let load = renditionRef?.current?.display(0)
        }
        loadVoices();
        if (speechSynthesisRef.onvoiceschanged !== undefined) {
            speechSynthesisRef.onvoiceschanged = loadVoices;
        }

        if (viewerRef.current && isLoaded) {
            setTextNodes()
        }
    }, [isLoaded])

    return (
        <div style={{ minHeight: '100dvh', display: 'flex', justifyContent: "flex-end", flexDirection: 'column' }}>
            <Navbar />
            <div className="read_to_me_epub_container">
                <EpubReader url={data?.url} bookLoadedFunc={handleBookLoaded} isLoaded={isLoaded} handleNextFunc={handleNext} handlePrevFunc={handlePrev} renditionRef={renditionRef} fontSize={100} setIsLoaded={setIsLoaded} currentPageIndex={currentPageIndex} lastPage={lastPage} viewerRef={viewerRef} tocLength={tocLength} setLocationChanged={setLocationChanged} selections={selections} setSelections={setSelections} />
                {
                    audioFile && <div className="read_along_recorded-audio">
                        <div className="read_along_audio_block">
                            <audio
                                src={audioFile}
                                controls="controls"
                                className="read_along_audio_element"
                            />
                        </div>
                        <div
                            className="audio_close_btn"
                            onClick={() => {
                                setAudioFile(null)
                            }}
                        >
                            X
                        </div>
                    </div>
                }

                {
                    micLoader &&

                    <div
                        className="read_along_mic_loader"
                        id="mic_listening"
                    >
                        <img
                            style={{ height: "3rem", marginTop: "4px", marginBottom: "4px" }}
                            className="img-recorder"
                            src={micListeningGif}
                            alt="Mic Listening"
                        />
                        <span style={{ fontSize: "15px" }}>Recording now</span>
                    </div>

                }
                <div style={{ display: "flex", justifyContent: "center" }}>
                    {showLoading ? (
                        <img
                            style={{ height: "4rem", justifyContent: "center" }}
                            className="loading-symbol"
                            src={Loader}
                            alt="loading"
                            id="load"
                        />
                    ) : null}
                </div>

                <div className="read_along_modal-buttons">

                    {showStartButton ? window.innerWidth>650 ? (
                        <div className="read_along_read_aloud_btns_container">
                            {
                                isReadAloud ?
                                    <button onClick={stopReadAloud} disabled={!isReadAloud}>
                                        <GiSpeakerOff />
                                    </button>
                                    :
                                    <button
                                        id="playAudio"
                                        className="play-button"
                                        onClick={startReadAloud}
                                        disabled={isReadAloud}
                                    >
                                        <GiSpeaker />
                                    </button>
                            }
                            {isPaused ? (
                                <button onClick={resumeReading} disabled={!isReadAloud}>
                                    <IoIosPlay />
                                </button>
                            ) : (
                                <button onClick={pauseReading} disabled={!isReadAloud}>
                                    <IoIosPause />
                                </button>
                            )}
                             <div className="pdf-page-count">
                                Page {currentPageIndex}
                            </div>
                            {stopRecButton ? (
                                <button
                                    onClick={stopRecording}
                                >
                                    <FaStop style={{ height: '22px' }} />
                                </button>
                            )
                                :
                                <button>
                                    <FaMicrophone style={{ height: '22px' }} onClick={startRecording} />
                                </button>
                            }
                            <div className="read-rate">
                                <label
                                    className="read-label"
                                    htmlFor="read-rate"
                                >
                                    Reading rate:
                                </label>
                                <input
                                    id="read-rate"
                                    type="range"
                                    min="0.5"
                                    max="1.5"
                                    value={playbackRate}
                                    step="0.1"
                                    onChange={updatePlaybackRate}
                                />
                                <output id="read-rate-output">{playbackRate}&times;</output>
                            </div>
                        </div>
                    )
                    :
                        <div className="read_along_read_aloud_btns_container" >
                            <button className='pdf_control_btn' onClick={handlePrev} disabled={currentPageIndex === 0}>{'<'}</button>
                            {
                                isReadAloud ?
                                    <button onClick={stopReadAloud} disabled={!isReadAloud}><GiSpeakerOff /></button>
                                    :
                                    <button onClick={startReadAloud} disabled={isReadAloud}><GiSpeaker /></button>
                            }

                            {stopRecButton ? (
                                <button
                                    onClick={stopRecording}
                                >
                                    <FaStop style={{ height: '22px' }} />
                                </button>
                            )
                                :
                                <button>
                                    <FaMicrophone style={{ height: '22px' }} onClick={startRecording} />
                                </button>
                            }

                            <div className="pdf-page-count">
                                {currentPageIndex}
                            </div>
                            {
                                isPaused ?
                                    <button onClick={resumeReading} disabled={!isReadAloud} ><IoIosPlay /></button>
                                    :
                                    <button onClick={pauseReading} disabled={!isReadAloud}><IoIosPause /></button>
                            }
                            <div className='playback_rate_container'>
                                <button onClick={() => setOpenSlider(!isSliderOpen)}><FaSlidersH /></button>

                                <div className={`read-rate ${isSliderOpen ? "open" : ""}`}>
                                    <input
                                        id="read-rate"
                                        type="range"
                                        min="0.5"
                                        max="1.5"
                                        value={playbackRate}
                                        step="0.1"
                                        onChange={updatePlaybackRate}
                                    />
                                    <output id="read-rate-output">{playbackRate}&times;</output>
                                </div>
                            </div>
                            {
                                lastPage ?
                                <button className='pdf_control_btn' onClick={FinishStory} disabled={!lastPage}><ImExit/></button>
                                :
                            <button className='pdf_control_btn' onClick={handleNext} disabled={lastPage || currentPageIndex === tocLength - 1}>{'>'}</button>
                            }

                        </div>
                    :null
                }
                {
                    !showStartButton && window.innerWidth<=650 &&
                        <div className="read_along_read_aloud_btns_container" >
                            <button className='pdf_control_btn' onClick={handlePrev} disabled={currentPageIndex === 0}>{'<'}</button>
                            <button className='pdf_control_btn' onClick={handleNext} disabled={lastPage || currentPageIndex === tocLength - 1}>{'>'}</button>
                        </div>
                }
                    {lastPage && window.innerWidth>650 && (
                        <Button type="primary"
                            className="last_page_exit_btn"
                            onClick={FinishStory}
                            id="recordButton"
                        >
                            Exit Story
                        </Button>
                    )}
                </div>
            </div>
        </div>
    );
};

export default connect(
    ({ detailedStory }) => ({ ...detailedStory }),
    (dispatch) => bindActionCreators({ ...storyActions }, dispatch)
)(StoryReadAloud);
