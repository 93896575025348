import { useState, useEffect } from "react";

const useTextToSpeech = () => {
  const [isReading, setIsReading] = useState(false);
  const [speechRef] = useState(window.speechSynthesis);
  const [voices, setVoices] = useState([]);

  // Fetch available voices when the hook is used
  const loadVoices = () => {
    const availableVoices = speechRef.getVoices();
    if (availableVoices.length > 0) {
      setVoices(availableVoices);
    } else {
      // If voices are not loaded yet, wait and try again
      setTimeout(() => {
        setVoices(speechRef.getVoices());
      }, 100);
    }
  };

  useEffect(() => {
    if (speechRef.onvoiceschanged !== undefined) {
      speechRef.onvoiceschanged = loadVoices;
    }
    loadVoices();

  }, []);


  const startReading = (text) => {
    if (!text) return;

    setIsReading(true);
    const utterance = new SpeechSynthesisUtterance(text);

    if (voices.length > 0) {
      let defaultVoice = voices.filter(
        (voice) => voice.lang === "en-IN" || voice.lang.includes("IN")
      );
      let voice = defaultVoice?.find((voice) => voice?.name?.includes("Heera"))
        ? defaultVoice?.find((voice) => voice?.name?.includes("Heera"))
        : defaultVoice?.length > 0
        ? defaultVoice[0]
        : voices[0];
      utterance.voice = voice;
      utterance.lang = voice?.lang ? voice?.lang : "en-IN";
    }

    utterance.rate = 0.75;
    utterance.pitch = 1;

    utterance.onend = () => setIsReading(false);
    speechRef.speak(utterance);
  };

  const stopReading = () => {
    speechRef.cancel();
    setIsReading(false);
  };

  return { isReading, startReading, stopReading };
};

export default useTextToSpeech;
