import React from "react";
import nlp from "compromise";
import speechPlugin from "compromise-syllables";

nlp.plugin(speechPlugin);

const useSyllables = (word) => {
  const doc = nlp(word);
  const terms = doc.terms()?.syllables();
  const result = terms.map((term) => {
    let syllables = term?.syllables;
    return syllables;
  });

  // console.log(nlp(word).terms().pronounce()[0]?.pronounce);
  return result[0];
};

export default useSyllables;
