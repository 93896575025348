import { Button } from 'antd'
import React from 'react'
import { MdOutlineClose } from "react-icons/md";
import './meaningModalStyles.scss'


const MeaningModal = ({ data, loading, closeModal,word }) => {
    // const meanings =data &&  data[1]?.value?.split('\n')
    return (
        <div className='meaning_modal_container'>
            <>
                <div className='meaning_modal_header'>
                    <p>{`Meaning of ${data ? data[0]?.value : word} `}</p>
                    <MdOutlineClose onClick={() => closeModal()} className='close_icon' />
                </div>
                {
                    loading ? <div className='meaning_modal_definition'>
                        <h6>Loading .... </h6>
                    </div>
                    :
                    data ?

                        <div className='meaning_modal_definition'>
                            {
                                data.map((meaningObj, index) => (
                                    <div className='meaning_container' key={index}>
                                        <p className='meaning_key'>{`${meaningObj?.key}: `}</p>
                                        <div className='meaning_value_container'>{meaningObj?.value?.split('\n').map((val, ind) => (
                                            <p key={ind} className='meaning_value'>{val}</p>
                                        )
                                        )}</div>
                                    </div>
                                ))
                            }
                        </div>
                        :
                        <div className='meaning_modal_definition'>
                            <h6>No meanings available for {word}</h6>
                        </div>
                }
            </>
        </div>
    )
}

export default MeaningModal