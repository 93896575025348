import React, { useState, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as storyActions from "store/story/actions";
import _debounce from 'lodash/debounce';
import { GiSpeaker, GiSpeakerOff } from "react-icons/gi";
import { IoIosPause, IoIosPlay } from "react-icons/io";
import * as EVENT from '../../utils/eventKeys'
import { formatDate } from "../../utils/commonUtils";
import { version } from '../../../package.json'
import { Analytics } from "../../services/analytics";
import { v4 as uuidv4 } from "uuid";
import { storage as LocalStorage } from "services/config/storage";
import { FaSlidersH } from 'react-icons/fa';
import EpubReader from "../../components/EpubReader/EpubReader";

const EpubReaderView = (props) => {

    const [isReading, setIsReading] = useState(false);
    const [isPaused, setIsPaused] = useState(false);
    const [currentWordIndex, setCurrentWordIndex] = useState(1);
    const [currentParaIndex, setCurrentParaIndex] = useState(1);
    const [fontSize, setFontSize] = useState(100);
    const [currentPageIndex, setCurrentPageIndex] = useState(0);
    const [locationChanged, setLocationChanged] = useState(false)
    const [lastPage, setLastPage] = useState(false)
    const [voices, setVoices] = useState([]);
    const [isLoaded, setIsLoaded] = useState(false)
    const [selectedVoice, setSelectedVoice] = useState('');
    const [tocLength, setTocLength] = useState(0)
    const [playbackRate, setPlaybackRate] = useState(1)
    const [isSliderOpen,setOpenSlider] = useState(false)
    const [selections,setSelections] = useState([])

    const renditionRef = useRef(null);
    const viewerRef = useRef(null);
    const utteranceRef = useRef(null);
    const textNodesRef = useRef([]);
    const view_id = uuidv4()


    useEffect(() => {
        if (!props.speechSynthesisRef.speaking) {
            setIsReading(false)
        }
    }, [props.speechSynthesisRef.speaking])

    const loadVoices = () => {
        const voices = props.speechSynthesisRef.getVoices();
        let defaultVoices = voices.filter((voice) => voice.localService === true)
        setVoices(defaultVoices);
        if (voices.length > 0) {
            let defaultVoice = voices.filter((voice) => voice.lang === 'en-IN' || voice.lang==='en_IN')
            // console.log(defaultVoice,voices)
            setSelectedVoice(defaultVoice?.find((voice) => voice?.name?.includes('Heera')) ? defaultVoice?.find((voice) => voice?.name?.includes('Heera'))?.name : defaultVoice?.length > 0 ? defaultVoice[0]?.name : voices[0].name);
        }
    };

    const setTextNodes = () => {
        if (viewerRef.current) {
            const doc_body = viewerRef.current.querySelector('iframe')?.contentWindow?.document

            let textNodes = getTextNodes(doc_body);
            textNodesRef.current = textNodes

        }
    }

    useEffect(() => {
        loadVoices();
        if (props.speechSynthesisRef.onvoiceschanged !== undefined) {
            props.speechSynthesisRef.onvoiceschanged = loadVoices;
        }

        if (viewerRef.current && isLoaded) {
            setTextNodes()
        }

    }, [isLoaded])

    useEffect(() => {
        if (renditionRef.current) {
            renditionRef.current.themes.fontSize(`${fontSize}%`);
        }
    }, [fontSize]);

    useEffect(() => {
        // console.log(`#word-${currentParaIndex}${currentWordIndex}`)
        // if(currentWordIndex ===0) return
        if (viewerRef.current) {
            // const doc = viewerRef.current.querySelector('iframe')?.contentWindow.document
            const doc_body = viewerRef.current.querySelector('iframe')?.contentWindow?.document?.body
            const words = doc_body?.getElementsByTagName('span');
            let currentWord = ''
            // console.log(words)
            for (let i = 0; i < words?.length; i++) {
                // console.log(words[i])
                words[i].classList.remove('highlight');
                words[i].style.background = "none"
            }
            if (currentWordIndex === 0 || currentParaIndex === 0) currentWord = doc_body?.querySelector(`#word-${currentParaIndex}${currentWordIndex + 1}`)
            else currentWord = doc_body?.querySelector(`#word-${currentParaIndex}${currentWordIndex + 1}`)
            // console.log(currentWord?.innerText, `#word-${currentParaIndex}${currentWordIndex}`)
            if (currentWord) {
                currentWord.classList.add('highlight');
                currentWord.style.background = "yellow"
            }
        }
    }, [currentWordIndex]);

    const handleNext = async () => {
        if (currentPageIndex < tocLength) {
            // console.log(renditionRef.current.currentLocation(),renditionRef.current.location)
            await renditionRef.current.next();
            if (locationChanged) {
                setCurrentPageIndex(currentPageIndex + 1)
                setLocationChanged(false)
                if (renditionRef.current.currentLocation()?.atEnd) {
                    setLastPage(true)
                }
            }
            props.speechSynthesisRef.cancel();
            setIsReading(false)
            setIsPaused(false)
            setFontSize(100)
            setTextNodes()
        } else {
            setLastPage(true)
        }
    };

    const handlePrev = async () => {
        await renditionRef.current.prev();
        setTextNodes()
        setLastPage(false)
        setCurrentPageIndex(currentPageIndex - 1)
        props.speechSynthesisRef.cancel();
        setIsReading(false)
        setIsPaused(false)
        setFontSize(100)
        // bookRef.current.pageFlip().flipPrev();
    };

    const handleFontIn = () => {
        if (renditionRef.current) {
            setFontSize((prevSize) => {
                const newSize = prevSize + 10;
                renditionRef.current.themes.fontSize(`${newSize}%`);
                return newSize;
            });
        }
    };

    const handleFontOut = () => {
        if (renditionRef.current) {
            setFontSize((prevSize) => {
                const newSize = prevSize - 10;
                renditionRef.current.themes.fontSize(`${newSize}%`);
                return newSize;
            });
        }
    };

    const startReading = () => {
        setIsReading(true)
        renditionRef.current.annotations.remove(selections.cfiRange, "highlight");
        const doc_body = viewerRef.current.querySelector('iframe').contentWindow.document.body

        let textHtml = `<div>`
        if (textNodesRef.current.length > 0) {
            textNodesRef.current.forEach((node, ind) => {
                // console.log(node)
                const content = node.type === 'text' || node.type === 'heading' || node.type === 'subheading' ? node.content : null
                const src = node.type === 'image' ? node.src : null
                if (node.type === 'text' || node.type === 'heading' || node.type === 'subheading') {
                    if (content) {
                        const words = content.split(/\s+/);
                        let wrappedText = ''
                        if (node.type === 'heading' || node.type === 'subheading') {
                            wrappedText = words.map((word, index) => `<strong><span style="font-weight=700;" id="word-${ind + 1}${index + 1}">${word} </span></strong>`).join('');
                        } else {
                            wrappedText = words.map((word, index) => `<span id="word-${ind + 1}${index + 1}" style="font-weight=550">${word} </span>`).join('');
                        }

                        textHtml = textHtml.concat(wrappedText)
                        textHtml = textHtml.concat('<br/><br/>')
                        utteranceRef.current = new SpeechSynthesisUtterance(content);
                        utteranceRef.current.rate = playbackRate
                        const voice = voices.find(voice => voice.name === selectedVoice);
                        // console.log(voice)
                        if (voice) {
                            utteranceRef.current.voice = voice;
                            utteranceRef.current.lang = voice?.lang ? voice?.lang : 'en-IN';
                        }

                        utteranceRef.current.onboundary = (event) => {
                            // console.log(event)
                            if (event.name === 'word') {
                                const charIndex = content.slice(0, event.charIndex).split(/\s+/).length - 1;
                                // console.log(charIndex,currentParaIndex,ind)
                                setCurrentWordIndex(charIndex);
                            }
                            setCurrentParaIndex(ind + 1)
                        };
                        utteranceRef.current.onend = () => {
                            // console.log('ending...',currentParaIndex)
                            setCurrentParaIndex((prev) => prev + 1)
                            setCurrentWordIndex(0);
                            // setIsReading(false)
                        };
                        props.speechSynthesisRef.speak(utteranceRef.current);
                        setIsReading(true);
                    }
                }
                else {
                    // console.log('inside else',src?.substring(0,src?.length))
                    textHtml = textHtml.concat(`<img src="${src}" style="max-height:400px !important; max-width:500px !important; margin-bottom:10px"/><br/>`)
                }
            })
            textHtml = textHtml.concat('</div>')

            doc_body.innerHTML = textHtml;
        }

        doc_body.style.display = "flex"
        doc_body.style.flexDirection = "column-reverse"
        doc_body.style.gap = '8px'

        logEvents(EVENT.READ_TO_ME_PLAY_BUTTON, {
            [EVENT.READ_TO_ME_STORY_NAME]: props.name,
            [EVENT.READ_TO_ME_STORY_URL]: props.url,
        });

    };

    const stopReading = () => {
        if (props.speechSynthesisRef.speaking) {
            props.speechSynthesisRef.cancel();
            setIsReading(false);
            setIsPaused(false)
            // setCurrentWordIndex(0);
            logEvents(EVENT.READ_TO_ME_STOP_BUTTON, {
                [EVENT.READ_TO_ME_STORY_NAME]: props.name,
                [EVENT.READ_TO_ME_STORY_URL]: props.url,
            });

        }
    };

    const getTextNodes = (doc) => {
        const extractedContent = [];
        let Alltext = ''

        // Helper function to process each element and its children recursively
        const processElement = (element, index) => {

            // Process paragraph <p> elements
            if (element.tagName.toLowerCase() === 'p') {
                const text = element.textContent.trim();
                if (text) {
                    extractedContent.push({
                        type: 'text',
                        content: text,
                        index: `text-${index}`,
                    });

                    Alltext = Alltext.concat(text)
                    Alltext = Alltext.concat(' ')
                }

                // Extract images inside the <p> element
                const imagesInParagraph = element.querySelectorAll('img');
                imagesInParagraph.forEach((img, imgIndex) => {
                    extractedContent.push({
                        type: 'image',
                        src: img.getAttribute('src'),
                        index: `image-${index}-${imgIndex}`,
                    });
                });
            }
            // Process image <img> elements directly
            else if (element.tagName.toLowerCase() === 'img') {
                extractedContent.push({
                    type: 'image',
                    src: element.getAttribute('src'),
                    index: `image-${index}`,
                });
            }
            // Process heading <h2> elements
            else if (element.tagName.toLowerCase() === 'h2') {
                const text = element.textContent?.trim();
                if (text) {
                    extractedContent.push({
                        type: 'heading',
                        content: text,
                        index: `heading-${index}`,
                    });
                    Alltext = Alltext.concat(text)
                    Alltext = Alltext.concat(' ')


                }
                const imagesInParagraph = element.querySelectorAll('img');
                imagesInParagraph.forEach((img, imgIndex) => {
                    extractedContent.push({
                        type: 'image',
                        src: img.getAttribute('src'),
                        index: `image-${index}-${imgIndex}`,
                    });
                });
            }
            // Process subheading <h3> elements
            else if (element.tagName.toLowerCase() === 'h3') {
                const text = element.textContent?.trim();
                if (text) {
                    extractedContent.push({
                        type: 'subheading',
                        content: text,
                        index: `subheading-${index}`,
                    });
                    Alltext = Alltext.concat(text)
                    Alltext = Alltext.concat(' ')
                }
                const imagesInParagraph = element.querySelectorAll('img');
                imagesInParagraph.forEach((img, imgIndex) => {
                    extractedContent.push({
                        type: 'image',
                        src: img.getAttribute('src'),
                        index: `image-${index}-${imgIndex}`,
                    });
                });
            }
            // Process <div> elements and recursively handle their children
            else if (element.tagName.toLowerCase() === 'div') {
                // Recursively process all child nodes of the current div
                Array.from(element.children).forEach((child, childIndex) => {
                    processElement(child, `${index}-${childIndex}`);
                });
            }

            // Add more checks if needed for other content types (e.g., videos, audio, etc.)
        };

        // Start processing the children of the body element
        Array.from(doc.body.children).forEach((child, index) => {
            processElement(child, index);
        });

        // setAllText(Alltext)

        return extractedContent;
    };

    const pauseReading = () => {
        props.speechSynthesisRef.pause()
        setIsPaused(true)
    }

    const resumeReading = () => {
        props.speechSynthesisRef.resume()
        setIsPaused(false)
    }

    const logEvents = (event, extraPayload) => {
        let payload = {};

        payload[EVENT.CHILD_ID] = LocalStorage.fetch.defaultChild()?.id;
        payload[EVENT.SESSION_ID] = LocalStorage.fetch.sessionData()?.session_data;
        payload[EVENT.SOURCE_PAGE_ID] = EVENT.READ_TO_ME_PAGE;
        payload[EVENT.TIMESTAMP] = formatDate(new Date());
        payload[EVENT.VIEW_ID] = view_id;
        payload[EVENT.CHILD_LEVEL] = LocalStorage.fetch.defaultChild()?.grade_level;
        payload[EVENT.SCHOOL_CODE] = LocalStorage.fetch.defaultChild()?.school_code;
        payload[EVENT.SOURCE] = "web";
        payload[EVENT.PARENT_ID] = LocalStorage.fetch.parentData()?.id;
        payload[EVENT.APP_VERSION] = version;
        Analytics.logEvents(event, { ...payload, ...extraPayload });
    };

    const handleBookLoaded = (book) => {
        // const toc = renditionRef?.current?.book?.navigation?.toc; // Access the TOC from the book
        const toc = renditionRef?.current?.book?.spine?.items; // Access the TOC from the book
        setTocLength(toc?.length); // Set the length of the TOC
        // console.log('Table of Contents:', toc,book,book.loaded,renditionRef.current?.book); // Optional: Inspect the TOC
    };

    const updatePlaybackRate = (e) => {
        const newPlaybackRate = parseFloat(e.target.value);
        setPlaybackRate(newPlaybackRate);
        stopReading()
    }

    return (
        <div className='epub_reader_view_container'>
            <EpubReader url={props?.url} bookLoadedFunc={handleBookLoaded} isLoaded={isLoaded} handleNextFunc={handleNext} handlePrevFunc={handlePrev} renditionRef={renditionRef} fontSize={100} setIsLoaded={setIsLoaded} currentPageIndex={currentPageIndex} lastPage={lastPage} viewerRef={viewerRef} tocLength={tocLength} setLocationChanged={setLocationChanged} selections={selections} setSelections={setSelections} />
               {
               window.innerWidth>650 ?
                    <div className="epub-controls" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '10px', gap: '40px' }}>

                        <button onClick={handleFontIn} disabled={fontSize === 200}>+</button>
                        {
                            currentPageIndex !== 0 &&
                            <div className="pdf-page-count">
                                Page {currentPageIndex}
                            </div>
                        }
                        <button onClick={handleFontOut} disabled={fontSize == 100}>-</button>
                        <button onClick={startReading} disabled={isReading}><GiSpeaker /></button>
                        <button onClick={stopReading} disabled={!isReading}><GiSpeakerOff /></button>
                        {
                            isPaused ?
                                <button onClick={resumeReading} disabled={!isReading} ><IoIosPlay /></button>
                                :
                                <button onClick={pauseReading} disabled={!isReading}><IoIosPause /></button>
                        }

                        <div className="read-rate">
                            <label
                                className="read-label"
                                htmlFor="read-rate"
                            >
                                Reading rate:
                            </label>
                            <input
                                id="read-rate"
                                type="range"
                                min="0.5"
                                max="1.5"
                                value={playbackRate}
                                step="0.1"
                                onChange={updatePlaybackRate}
                            />
                            <output id="read-rate-output">{playbackRate}&times;</output>
                        </div>
                    </div>
                    :
                    <div className="epub-controls" >

                        <button className='pdf_control_btn' onClick={handlePrev} disabled={currentPageIndex === 0}>{'<'}</button>
                        <button onClick={handleFontIn} disabled={fontSize === 200}>+</button>
                        {
                            isReading ?
                                <button onClick={stopReading} disabled={!isReading}><GiSpeakerOff /></button>
                                :
                                <button onClick={startReading} disabled={isReading}><GiSpeaker /></button>
                        }

                        <div className="pdf-page-count">
                            {currentPageIndex}
                        </div>
                        {
                            isPaused ?
                                <button onClick={resumeReading} disabled={!isReading} ><IoIosPlay /></button>
                                :
                                <button onClick={pauseReading} disabled={!isReading}><IoIosPause /></button>
                        }
                        <button onClick={handleFontOut} disabled={fontSize == 100}>-</button>
                        <div className='playback_rate_container'>
                            <button onClick={() => setOpenSlider(!isSliderOpen)}><FaSlidersH /></button>

                            <div className={`read-rate ${isSliderOpen ? "open" : ""}`}>
                                {/* <label
                                        className="read-label"
                                        htmlFor="read-rate"
                                    >
                                        Reading rate:
                                    </label> */}
                                <input
                                    id="read-rate"
                                    type="range"
                                    min="0.5"
                                    max="1.5"
                                    value={playbackRate}
                                    step="0.1"
                                    onChange={updatePlaybackRate}
                                />
                                <output id="read-rate-output">{playbackRate}&times;</output>
                            </div>
                        </div>
                        <button className='pdf_control_btn' onClick={handleNext} disabled={lastPage || currentPageIndex === tocLength - 1}>{'>'}</button>

                    </div>
            }

        </div>
    );
};

export default connect(
    ({ detailedStory }) => ({ ...detailedStory }),
    (dispatch) => bindActionCreators({ ...storyActions }, dispatch)
)(EpubReaderView);

